<template>
  <div class="child-view">
    <div>
      <van-sticky>
        <van-search v-model="filters.name"
          placeholder="请选择服务机构"
          @click="soIdShow = true"
          readonly="readonly"
          shape="round"
          background="#F2F2F2"
          left-icon="https://jmylapi.zhsq.cloud//upload/files/2022/11/ZR2uIACzwrnj6968bb9baa4a2b20d6e27daf88a25a15.png">
        </van-search>
        <van-popup v-model="soIdShow"
          position="bottom"
          get-container="body">
          <van-picker show-toolbar
            :columns="soIdList"
            value-key="Name"
            @cancel="soIdShow = false"
            @confirm="onSoId">
          </van-picker>
        </van-popup>
      </van-sticky>

    </div>
    <!-- banner切换 -->
    <van-swipe class="homebanner"
      :autoplay="3000"
      indicator-color="white">
      <van-swipe-item v-for="banner in bannerImgs"
        :key="banner"><img :src="banner"
          style="height:172px;">
      </van-swipe-item>
    </van-swipe>
    <div class="box">
      <van-row gutter="10">
        <van-col span="12">
          <div class="boxTj"
            style="background: #fec955;">
            <div>
              处理中
              <div style="margin-top:5px;font-weight: 550;font-size: 20px;">
                {{clzNum}}
              </div>
            </div>
            <div class="oldmanImg">
              <img src="https://xajmylapi.anluoyun.cn//upload/images/2023/1/31c2f1f9-853.png"
                style="width:38px;height:38px;" />
            </div>

          </div>
        </van-col>
        <van-col span="12">
          <div class="boxTj"
            style="background: #4177fe;">
            <div>
              <span>待验收</span>
              <div style="margin-top:5px;font-weight: 550;font-size: 20px;">
                {{dysNum}}
              </div>
            </div>

            <div class="oldmanImg">
              <img src="https://xajmylapi.anluoyun.cn//upload/images/2023/1/c7f70980-584.png" />
            </div>
          </div>
        </van-col>
      </van-row>
      <van-row gutter="10"
        style="margin-top:10px;">
        <van-col span="12">
          <div class="boxTj"
            style="background: #23d87b;">
            <div>
              助洁
              <div style="margin-top:5px;font-weight: 550;font-size: 20px;">
                {{labour.zjNum}}
              </div>
            </div>
            <div class="oldmanImg">
              <img src="https://xajmylapi.anluoyun.cn//upload/images/2023/1/f31340a9-ecb.png" />
            </div>

          </div>
        </van-col>
        <van-col span="12">
          <div class="boxTj"
            style="background: #f988a2;">
            <div>
              <span>巡访</span>
              <div style="margin-top:5px;font-weight: 550;font-size: 20px;">
                {{labour.xfgaNum}}
              </div>
            </div>

            <div class="oldmanImg">
              <img src="https://xajmylapi.anluoyun.cn//upload/images/2023/1/9ffdcf62-498.png" />
            </div>
          </div>
        </van-col>
      </van-row>
    </div>
    <!-- 养老服务统计 -->
    <div class="box"
      style="background:white;padding: 10px 0 ;">
      <div style="margin:5px 10px;">
        <div class="biankuang1"></div>
        <div class="title">
          服务趋势
        </div>
      </div>
      <!-- <van-sticky>
        <div style="background-color: white">
          <van-tabs swipeable
            @click="onClickTab"
            color="#4393ff"
            background="#4393ff00"
            title-active-color="#4393ff"
            style="width:50%;">
            <van-tab v-for="item in soIdList"
              :key="item.Id"
              :name="item.Id"
              :title="item.Name">
            </van-tab>
          </van-tabs>
        </div>
      </van-sticky> -->
      <div style="width: 95%; margin: 10px auto">
        <div id="qushi"
          style="width: 100%; height: 250px;"></div>
      </div>
    </div>
    <div class="box"
      style="background:white;padding: 10px 0 ;margin-bottom:80px;">
      <div>
        <div style="margin:5px 10px;">
          <div class="biankuang1"></div>
          <div class="title">
            服务同比
          </div>
        </div>

        <div style="width: 95%; margin: 10px auto">
          <div id="tongbi"
            style="width: 100%; height: 380px;"></div>
        </div>
      </div>
    </div>
    <van-tabbar v-model="active"
      @change="onChangeTab"
      active-color="#0f97ff"
      inactive-color="#000">
      <van-tabbar-item icon="home-o">首页</van-tabbar-item>
      <van-tabbar-item icon="orders-o">服务工单</van-tabbar-item>
      <van-tabbar-item icon="manager-o">我的</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import { WeGetAppletInfo } from "@/api/index";
import {
  UnitWorkorderStat,
  UnitWorkorderTrend,
  WorkOrderTrendOfDay,
  ServiceStat,
  WorkOrderStat,
  GetSerOrganList,
  GetWorkOrderPage
} from "@/api/oldman";
import { getwgToken, setwgToken } from "@/utils/auth";
import wx from "weixin-js-sdk";
import { Dialog } from 'vant';
import Vue from 'vue';
import { Toast } from 'vant';
Vue.use(Toast);
export default {
  data () {
    return {
      active: 0,
      bannerImgs: [],
      soIdList: [],
      soId: 6,
      labour: {},
      badgeNum: {},
      soIdShow: false,
      filters: {
        name: '',
        soId: '',
      },
      clzNum: '',
      dysNum: '',
    }
  },
  created () {
    // console.log(this.$route.query["wgToken"]);
    setwgToken(this.$route.query["wgToken"]);
    this.getAppInfo();
    this.getSerOrganList()
    this.geServiceStat()
  },
  methods: {
    // 获取服务机构
    getSerOrganList () {
      GetSerOrganList({ accToken: getwgToken() }).then((res) => {
        if (res.data.code == 0) {
          this.soIdList = res.data.data
          this.filters.soId = this.soIdList[0].SOId
          this.filters.name = this.soIdList[0].Name
          this.getWorkOrderTrend()
          this.workOrderTrendOfDay()
          this.getWorkOrderStat()
        }
        else if (res.data.code == 1) {
          Dialog({ message: res.data.msg }).then(() => {
            if (window.__wxjs_environment === "miniprogram") {
              window.wx.miniProgram.redirectTo({
                url: "pages/allShouye/index",
              });
            } else {
              alert('请在小程序中打开')
            }
          });
        } else {
          Toast(res.data.msg);
        }
      })
    },
    // 机构选择
    onSoId (val) {
      this.filters.soId = val.SOId;
      this.filters.name = val.Name;
      this.soIdShow = false;
      this.getWorkOrderTrend()
      this.workOrderTrendOfDay()
      this.getWorkOrderStat()
    },
    // 顶部轮播图
    getAppInfo () {
      WeGetAppletInfo().then((res) => {
        var list = res.data;
        if (list.code == 0) {
          var imgs = [];
          imgs[0] = list.data.SysPic1Url;
          imgs[1] = list.data.SysPic2Url;
          imgs[2] = list.data.SysPic3Url;
          this.bannerImgs = imgs;
        }
      });
    },
    // 待验收、处理中
    getWorkOrderStat () {
      GetWorkOrderPage({ accToken: getwgToken(), soId: this.filters.soId, status: 3, }).then((res) => {
        if (res.data.code == 0) {
          // console.log(res.data.count);
          this.clzNum = res.data.count;
        }
      })
      GetWorkOrderPage({ accToken: getwgToken(), soId: this.filters.soId, status: 4, }).then((res) => {
        if (res.data.code == 0) {
          this.dysNum = res.data.count;
        }
      })
    },
    // 服务次数
    geServiceStat () {
      ServiceStat({ accToken: getwgToken(), soId: this.soId }).then((res) => {
        if (res.data.code == 0) {
          this.labour = res.data
        }
      })
    },
    // tab切换
    onClickTab (name) {
      console.log(name);
      this.soId = name;
      this.getWorkOrderTrend()
      this.workOrderTrendOfDay()
    },
    // 服务趋势
    getWorkOrderTrend () {
      UnitWorkorderTrend({ soId: this.filters.soId }).then((res) => {
        var list = res.data;
        if (list.code == 0) {
          var StatDay = [];
          var OrderCount = [];
          //   console.log(list.data);
          if (list.data.length == "1") {
            for (let i = 0; i < list.data[0].Detail.length; i++) {
              const e = list.data[0].Detail[i];
              StatDay.push(e.Days);
              OrderCount.push(e.SerNum);
            }
            this.getTxTongJi(StatDay, OrderCount);
          }
        }
      });
    },
    // 服务趋势
    getTxTongJi (StatDay, OrderCount) {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById("qushi"));
      // 绘制图表
      myChart.setOption({
        // title: {
        //   text: '服务趋势',
        //   x: '0.5%',
        //   top: '1%',
        //   textStyle: {
        //     color: 'black',
        //     fontSize: 14,
        //     fontWeight: 'normal',
        //   }
        // },
        legend: {
          show: true,
          itemWidth: 16,
          zlevel: 1, // 所有图形的 zlevel 值。
          left: 'right',
          data: ['服务次数'],
          textStyle: {
            color: 'black',
          },
        },
        tooltip: {
          trigger: 'axis',
          textStyle: {
            fontSize: 16,
            color: 'black'
          },
        },
        grid: {
          top: "16%",
          left: "1%",
          right: "1%",
          bottom: "8%",
          containLabel: true,
        },
        xAxis: [{
          type: "category",
          boundaryGap: true,
          axisLine: {
            //坐标轴轴线相关设置。数学上的x轴
            show: true,
            lineStyle: {
              color: "#f9f9f9",
            },
          },
          axisLabel: {
            //坐标轴刻度标签的相关设置
            interval: 0, //横轴信息全部显示
            rotate: 60, //60度角倾斜显示
            textStyle: {
              color: "black",
              margin: 15,
            },
          },
          axisTick: {
            show: false,
          },
          data: StatDay,
        }],
        yAxis: [{
          type: 'value',
          splitNumber: 4,
          splitLine: {
            lineStyle: {
              type: 'dashed',
              color: '#DDD'
            }
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#eee"
            },
          },
          nameTextStyle: {
            color: "#999"
          },
          splitArea: {
            show: false
          },
          axisLabel: {
            margin: 20,
            textStyle: {
              color: "black",
            },
          },
        }],
        series: [{
          name: '服务次数',
          type: 'line',
          data: OrderCount,
          label: {
            show: true,
            position: "top",
            textStyle: {
              color: "black",
            },
          },
          lineStyle: {
            normal: {
              width: 4,
              color: {
                type: 'linear',

                colorStops: [{
                  offset: 0,
                  color: '#A9F387' // 0% 处的颜色
                }, {
                  offset: 1,
                  color: '#48D8BF' // 100% 处的颜色
                }],
                globalCoord: false // 缺省为 false
              },
              shadowColor: 'rgba(72,216,191, 0.3)',
              shadowBlur: 10,
              shadowOffsetY: 20
            }
          },
          itemStyle: {
            normal: {
              color: '#33ccff',
              borderWidth: 10,
              shadowColor: 'rgba(72,216,191, 0.3)',
              shadowBlur: 100,
              borderColor: "#A9F387"
            }
          },
          smooth: true
        }]
      });
    },
    // 服务同比
    workOrderTrendOfDay () {
      WorkOrderTrendOfDay({ accToken: getwgToken(), soId: this.filters.soId }).then((res) => {
        if (res.data.code == 0) {
          var value = res.data.data
          // console.log(value)
          var todayNum = []
          var statTime = []
          var beforeYesterdayNum = []
          var yesterdayNum = []
          for (let i = 0; i < value.length; i++) {
            todayNum.push(value[i].TodayNum)
            statTime.push(value[i].StatTime)
            beforeYesterdayNum.push(value[i].BeforeYesterdayNum)
            yesterdayNum.push(value[i].YesterdayNum)
          }
          this.echartsWorkOrderTrendOfDay(todayNum, statTime, beforeYesterdayNum, yesterdayNum)
        }
      });
    },
    // 服务同比
    echartsWorkOrderTrendOfDay (todayNum, statTime, beforeYesterdayNum, yesterdayNum) {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById("tongbi"));
      let titleArray = ['前天', '昨天', '今天']
      myChart.setOption({
        // title: {
        //   text: '服务同比',
        //   x: '0.5%',
        //   top: '0%',
        //   textStyle: {
        //     color: 'black',
        //     fontSize: 14,
        //     fontWeight: 'normal',
        //   }
        // },
        grid: {
          top: '10%',
          right: '5%',
          left: '0%',
          bottom: '0%',
          containLabel: true,
        },
        tooltip: {
          show: true,
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
          textStyle: {
            fontSize: 16,
            color: 'black'
          },
          // 自定义 tooltip
          formatter: function (params) {
            let dom = `${params[0].name}
              <br/>`
            for (let i of params) {
              dom += `${i.marker}
              ${i.seriesName}
                : 
              ${i.value}
              <br/>`
            }
            return dom
          },
        },
        legend: {
          show: true,
          itemWidth: 16,
          top: '2%',
          left: 'right',
          data: titleArray,
          textStyle: {
            color: 'black',
          },
        },
        yAxis: [
          {
            type: 'category',
            color: '#9da2b7',
            data: statTime,
            axisLabel: {
              margin: 20,
              color: '#black',
              textStyle: {
                fontSize: 12,
              },
            },
            axisLine: {
              lineStyle: {
                color: '#eee',
              },
            },
            axisTick: {
              show: true,
              lineStyle: {
                color: '#eee',
              },
            },
          },
        ],
        xAxis: [
          {
            min: 0,
            minInterval: 1,
            axisLabel: {
              color: 'black',
              textStyle: {
                fontSize: 12,
              },
            },
            axisLine: {
              lineStyle: {
                color: '#eee',
              },
            },
            axisTick: {
              show: true,
            },
            splitLine: {
              lineStyle: {
                color: '#eee',
                type: 'dashed',
              },
            },
          },
        ],
        series: [
          {
            name: '前天',
            type: 'bar',
            data: beforeYesterdayNum,
            barWidth: '10px',
            itemStyle: {
              normal: {
                color: '#c37dff',
                barBorderRadius: [0, 10, 10, 0],
              },
            },
            label: {
              show: true,
              position: "right",
              textStyle: {
                color: "black",
              },
            },
          },
          {
            name: '昨天',
            type: 'bar',
            data: yesterdayNum,
            barWidth: '10px',
            itemStyle: {
              normal: {
                color: '#d0a00e',
                barBorderRadius: [0, 10, 10, 0],
              },
            },
            label: {
              show: true,
              position: "right",
              textStyle: {
                color: "black",
              },
            },
          },
          {
            name: '今天',
            type: 'bar',
            data: todayNum,
            barWidth: '10px',
            itemStyle: {
              normal: {
                color: '#33CCFF',
                barBorderRadius: [0, 10, 10, 0],
              },
            },
            label: {
              show: true,
              position: "right",
              textStyle: {
                color: "black",
              },
            },
          },
        ],
      })
    },
    // 底部导航栏切换
    onChangeTab: function (row) {
      console.log(row);
      if (row == "0") {
        if (window.__wxjs_environment === "miniprogram") {
          window.wx.miniProgram.redirectTo({
            url: "pages/admin/adminH5/home?path=/adminWorkOrderAll/adminWorkOrderAll",
          });
        } else {
          alert('请在小程序中打开')
        }
        // this.$router.push({
        //   path: "/ylOrder/orderList",
        // });
      } else if (row == "1") {
        if (window.__wxjs_environment === "miniprogram") {
          window.wx.miniProgram.redirectTo({
            url: "../../admin/adminWorkOrderAll/adminWorkOrderAll",
          });
        } else {
          alert('请在小程序中打开')
        }
      } else if (row == "2") {
        if (window.__wxjs_environment === "miniprogram") {
          window.wx.miniProgram.redirectTo({
            url: "../../admin/adminMy/adminMy",
          });
        } else {
          alert('请在小程序中打开')
        }
      }
    },

  }
}
</script>

<style scoped>
.child-view {
  min-height: 100%;
  background: #f5f5f5;
}
.box {
  width: 95%;
  margin: 10px auto;
}
.boxTj {
  background: #23d87b;
  height: 70px;
  border-radius: 5px;
  color: white;
  display: flex;
  justify-content: space-around;
  align-content: center;
  flex-wrap: wrap;
}
.homebanner {
  width: 95%;
  margin: auto;
  /* margin-top: 15px; */
}

.homebanner img {
  width: 100%;
  vertical-align: top;
  border-radius: 5px;
}
.oldmanImg img {
  padding-top: 5px;
  width: 40px;
  height: 40px;
}
.title {
  font-size: 16px;
  margin-left: 10px;
  line-height: 20px;
}
.biankuang1 {
  /* border-left: 4px solid #6182FA; */
  border: 2px solid #13a8ff;
  background-color: #13a8ff;
  width: 0;
  height: 17px;
  float: left;
  border-radius: 2px;
  /* margin: 0 8px; */
}
/deep/.van-tabbar-item__icon {
  font-size: 18px !important;
  margin-bottom: 5px !important;
}
</style>